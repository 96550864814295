header {
  background-color: #312e2e;
  background-image: url("../images/climpek-background.png");
  margin: auto -16px;
  padding: 16px 32px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.page {
  background-color: #312e2e;
  font-family: 'Georgia', ' serif', ' Segoe UI', ' Roboto', ' Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans';
  background-image: url("../images/climpek-background.png");
}

#footer {
  color: white;
  padding: 0 0 6rem 6rem;
  margin-left: 0;
}